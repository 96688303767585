import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map } from 'rxjs';
import { CoreAuthConfigService } from '@features/auth/core-auth-config.service';
import { URI_APIs } from '@features/auth/const';

@Injectable()
export class DragDropImageService {
  constructor(private readonly httpClient: HttpClient) {}
  conf = inject(CoreAuthConfigService);

  loadImage(fileV2: any) {
    const url = this.conf.buildFileV2URL(URI_APIs.GET_LIST);

    return this.httpClient.post<Array<any>>(url, {
      list_server_file_name: [fileV2.server_file_name],
    });
  }

  upload = (blob: Blob) => {
    const originalFileName = 'avatar.png';
    const serverFolderName = '';
    const fileExtension = originalFileName.split('.').pop();

    const formData = new FormData();
    formData.append('type', 'image');
    formData.append('folder_type', serverFolderName);
    formData.append('ext', fileExtension ?? '');
    formData.append('files', blob, originalFileName);

    const url = this.conf.buildFileV2URL(URI_APIs.UPLOAD_FILE);
    return this.httpClient
      .post(url, formData)
      .pipe(map((e: any) => e['files'][0]));
  };

  download(serverFileName: string) {
    const requestBody = {
      list_server_file_name: [serverFileName],
    };
    const url = this.conf.buildFileV2URL(URI_APIs.DOWNLOAD_FILE);
    return this.httpClient.post(url, requestBody, {
      responseType: 'blob',
    });
  }
}
