<div class="modal-header">
  <h3 class="modal-title">Sửa hình ảnh đại diện</h3>
  <button class="close" (click)="this.onClose()">
    <span aria-hidden="true"><i class="fal fa-times"></i></span>
  </button>
</div>

<div class="modal-body">

  <div class="row" [class.justify-content-center]="!this._imageCropped && !this.imageUrl">
    <div class="col-auto align-self-center">
      <div class="d-flex flex-column align-items-center gap-8">
        <div class="position-relative image__upload" (click)="fileUpload.click()">
          <img [src]="_imageCropped" onerror="this.src='assets/img/logo.png'" alt="Avatar placeholder"
            style="max-width: 11rem; height: 11rem;" class="image__upload-avatar rounded-circle shadow-2 img-thumbnail"
            alt="">
          <div class="image__upload-text text-white fs-lg text-nowrap text-center"><i class="fal fa-camera d-block"></i> Tải
            ảnh lên</div>
          <input type="file" #fileUpload (change)="fileChangeEvent($event)" class="d-none" />
        </div>
        <a href="javascript:void(0)" (click)="onRemoveAvatar()" class="hover-underline">Khôi phục</a>
      </div>
    </div>
      <div class="" [class.col-auto]="!this._imageCropped && !this.imageUrl" [class.col]="croppedBlob || imageChangedEvent || this._imageCropped || this.imageUrl">
        <div class="mx-auto" style="width: 100%; max-height: 30rem;" image-cropper [imageChangedEvent]="imageChangedEvent" [imageURL]="this.imageUrl" [maintainAspectRatio]="true"
        [aspectRatio]="1" format="png" [alignImage]="'center'" (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"></div>
      </div>
  </div>
</div>

<div class="modal-footer justify-content-center align-items-center">
  <button class="btn btn-outline-default m-0 mr-3" style="cursor: pointer" [disabled]="this.isSaving"
    [style.cursor]="this.isSaving ? 'not-allowed' : 'pointer'" (click)="this.onClose()">
    Quay lại
  </button>
  <button class="btn btn-primary m-0 cursor-default" [disabled]="this.isSaving"
    [style.cursor]="this.isSaving ? 'not-allowed' : 'pointer'" (click)="onSubmit()" style="width: 9rem;">
    <span *ngIf="isSaving" class="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span>
    Cập nhật
  </button>
</div>
