import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { languagePathBuilder } from '@features/auth/shared/languages';
import { TranslocoModule, provideTranslocoScope } from '@ngneat/transloco';
import { NgbActiveModal } from '@shared/components/lib-ng/lib-ng-bootstrap/modal/modal-ref';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslocoModule],
  providers: [provideTranslocoScope('auth')],
  selector: 'app-change-email-success',
  templateUrl: './change-email-success.component.html',
  styleUrls: ['./change-email-success.component.css'],
})
export class ChangeEmailSuccessComponent implements OnInit {
  public activeModal = inject(NgbActiveModal);

  LANUGAGE = languagePathBuilder([
    'component',
    'account',
    'changeEmail',
    'formEmail',
  ]);
  ngOnInit() {}
}
