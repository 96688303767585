<!-- #Body -->
<div *transloco="let translated">
  <div class="modal-body">
    <div class="d-flex flex-column justify-content-center text-center">
      <div class="d-flex  justify-content-center mb-3">
        <span class="icon-stack fa-6x">
          <i class="base-4 icon-stack-3x color-danger-500"></i>
          <i class="base-4 icon-stack-1x color-danger-400"></i>
          <i class="ni ni-envelope icon-stack-1x text-white"></i>
        </span>
      </div>
      <h2>
        <strong>
          <!-- {{ translated(this.LANUGAGE + '.submitEmailSuccessTitle')}} -->
          Chúc mừng! Địa chỉ email của bạn đã được đổi thành công
        </strong>
      </h2>
      <h5>
        {{ translated(this.LANUGAGE + '.submitEmailSuccessDesc')}}
      </h5>
    </div>
  </div>
  <!-- #Footer -->
  <div class="modal-footer justify-content-center border-top">
    <button class="btn btn-primary px-5"
            type="button"
            (click)="this.activeModal.dismiss(true)">
      <span> {{ translated(this.LANUGAGE + '.continueUseApp')}}</span>
    </button>
  </div>
</div>
