import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@shared/components/lib-ng/lib-ng-bootstrap/modal/modal-ref';
import { ToastrService } from '@shared/components/lib-ngx/ngx-toastr/toastr/toastr.service';
import {
  ImageCroppedEvent,
  ImageCropperComponent,
  LoadedImage,
} from '@shared/components/lib-ngx/public-api';
import { DragDropImageService } from './drag-drop-image.service';
import { ZaaFileV2LoaderDirective } from '@shared/directives/zaa-load-file-v2/zaa-load-file-v2.directive';
import { IFileV2 } from '@shared/directives/zaa-load-file-v2/file-v2.interface';
@Component({
  standalone: true,
  imports: [CommonModule, ImageCropperComponent, ZaaFileV2LoaderDirective],
  providers: [DragDropImageService],
  selector: 'app-drag-drop-image',
  templateUrl: './drag-drop-image.component.html',
  styleUrls: ['./drag-drop-image.component.scss'],
})
export class DragDropImageComponent implements OnInit {
  @Input() fileV2!: IFileV2;

  protected activeModal = inject(NgbActiveModal);
  protected toastService = inject(ToastrService);

  isActiveUpload = false;

  service = inject(DragDropImageService);

  imageChangedEvent: Event | null = null;
  isSaving = false;
  croppedBlob: Blob | null = null;
  imageUrl: any;

  imageUrlOld: any;

  _imageCropped: any;

  constructor(private sanitizer: DomSanitizer) { }
  ngOnInit(): void {
    this.service.loadImage(this.fileV2).subscribe({
      next: (value) => {
        if (value) {
          this.imageUrl = value[0].url;
          this.imageUrlOld = this.imageUrl;
        }
      },
    });
  }

  fileChangeEvent(event: Event): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    if (event.objectUrl) {
      this._imageCropped = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
    }
    if (event.blob) {
      this.croppedBlob = event.blob;
    }
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
    this.toastService.warning('Load image failed.');
  }

  onClose = (): void => {
    this.activeModal.close();
    // this.router.navigateByUrl(this.callbackURL);
  };

  onSuccess(url: string) {
    this.activeModal.close(url);
  }

  onSubmit() {
    if (this.isSaving) {
      return;
    }
    this.isSaving = true;

    if (!this.croppedBlob) {
      this.toastService.warning('Vui lòng chọn ảnh đại diện', 'Cảnh báo');
      this.isSaving = false;
      return;
    }

    this.service.upload(this.croppedBlob).subscribe({
      next: (value: any) => {
        this.isSaving = false;
        this.toastService.success('Upload ảnh thành công', 'Thành công');
        this.imageUrlOld = this._imageCropped;
        this.activeModal.close(value);
      },
      error: (err) => {
        console.error(err);
        this.isSaving = false;
        this.toastService.error(
          'Có lỗi xảy ra trong quá trình upload ảnh',
          'Lỗi hệ thống'
        );
      },
    });
  }

  onRemoveAvatar(): void {
    this._imageCropped = this.imageUrlOld;
    this.croppedBlob = null;
    this.imageChangedEvent = null;
    this.imageUrl = null;
    // from(
    //   Swal.fire({
    //     title: 'Xác nhận xóa ảnh đại diện',
    //     text: 'Bạn có chắc muốn xóa ảnh đại diện của bạn? Sau khi xóa bạn sẽ dùng ảnh đại hiện mạc định',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: 'Xóa',
    //     cancelButtonText: 'Hủy',
    //     reverseButtons: true,
    //   })
    // ).subscribe({
    //   next: (value) => {
    //     if (value.isConfirmed) {
    //       this.getImageFile();
    //     }
    //   },
    // });
  }
}
