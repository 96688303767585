import {
  Directive,
  Input,
  OnInit,
  OnDestroy,
  ElementRef,
  Renderer2,
  HostListener,
  isDevMode,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { CountDownHelper } from '../helpers/start-countdown.helper';

@Directive({
  standalone: true,
  selector: '[countdownButton]',
})
export class CountdownButtonDirective implements OnInit, OnDestroy {
  DEFAULT_RESEND_EMAIL_TIME = isDevMode() ? 3 : 60; //ms

  @Input('countdownButton') countdownSeconds: number | undefined =
    this.DEFAULT_RESEND_EMAIL_TIME;
  @Input('startWhenInit') startWhenInit: boolean = true;
  @Input() countdownMessage: string = '';

  public subscription!: Subscription;

  constructor(
    public el: ElementRef,
    public renderer: Renderer2,
  ) {}

  public startCountdown(): void {
    this.subscription = CountDownHelper.startCountdown(
      this.countdownSeconds,
      (secondsRemaining: number) => {
        if (secondsRemaining >= 0) {
          this.updateButtonLabel(secondsRemaining);
        }
      },
      () => {
        // Countdown completed
        this.enableButton();
        this.updateButtonLabelWhenFinish();
      },
    );
  }

  public clearCountdown(): void {
    this.subscription?.unsubscribe();
  }

  @HostListener('click')
  onClick(): void {
    this.clearCountdown();
    this.disableButton();
    this.startCountdown();
  }

  ngOnInit(): void {
    if (!this.countdownSeconds) {
      this.countdownSeconds = this.DEFAULT_RESEND_EMAIL_TIME;
    }

    if (
      this.countdownSeconds &&
      this.countdownSeconds > 0 &&
      this.startWhenInit
    ) {
      this.disableButton();
      this.startCountdown();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public disableButton(): void {
    this.renderer.setProperty(this.el.nativeElement, 'disabled', true);
  }

  public enableButton(): void {
    this.renderer.setProperty(this.el.nativeElement, 'disabled', false);
  }

  public updateButtonLabel(secondsRemaining: number): void {
    this.renderer.setProperty(
      this.el.nativeElement,
      'innerText',
      `${this.countdownMessage} (${secondsRemaining})`,
    );
  }

  public updateButtonLabelWhenFinish(): void {
    this.renderer.setProperty(
      this.el.nativeElement,
      'innerText',
      `${this.countdownMessage}`,
    );
  }
}
