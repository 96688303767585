<pdf-shy-button
  primaryToolbarId="presentationMode"
  [cssClass]="showPresentationModeButton | responsiveCSSClass : 'hiddenLargeView'"
  title="Switch to Presentation Mode"
  l10nid="presentation_mode"
  l10nLabel="presentation_mode_label"
  [order]="100"
  image="<svg style='width: 27px; height: 27px; margin-top: -2px' viewBox='0 0 24 24'><path fill='currentColor' d='M5,5H10V7H7V10H5V5M14,5H19V10H17V7H14V5M17,14H19V19H14V17H17V14M10,17V19H5V14H7V17H10Z' /></svg>"
>
</pdf-shy-button>
