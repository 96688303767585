<!-- #Body -->
<div *transloco="let translated">
  <div class="modal-body">
    <div class="d-flex flex-column justify-content-center text-center">
      <p class="text-left">
        1. Hệ thống đã gửi mã xác minh
        <strong> 6 chữ số </strong> đến địa chỉ email
        <strong>{{ this.emailAddress }}</strong> hãy mở email và tìm mã xác
        minh.
        <br />
        2. Nếu chưa nhận được email trong vòng
        <strong>60 giây </strong> bạn có thể nhấn nút "Gửi lại mã" bên dưới để
        nhận 1 mã xác minh mới. <br /><strong>
          Lưu ý:
          <span class="text-danger"
            >Mã này chỉ được dùng một lần và không cung cấp mã này cho bất kỳ ai
            để đản bảo an toàn cho tài khoản.</span
          ></strong
        >
      </p>
      <div class="d-flex justify-content-center">
        <ng-otp-input
          [config]="this.INPUT_VERIFY_CODE_CONFIG"
          (onInputChange)="inputOTPChange($event)"
        >
        </ng-otp-input>
      </div>

      @if(this.displayErrorMessage) {
      <div class="invalid-feedback fs-md d-block mt-1">
        {{ this.displayErrorMessage }}
      </div>
      }
      <div class="d-flex justify-content-center">
        <button
          class="btn btn-outline-default mt-5 border-top-0 border-left-0 border-right-0 border-bottom-0"
          style="cursor: pointer"
          [countdownButton]
          [countdownMessage]="translated(this.LANUGAGE + '.resendOTP')"
          (click)="onResendEmail()"
        >
          {{ translated(this.LANUGAGE + ".resendOTP") }}
        </button>
      </div>
    </div>
  </div>

  <!-- #Footer -->
  <div class="modal-footer justify-content-center border-top">
    <button
      class="mr-3 btn btn-outline-default"
      type="button"
      (click)="this.previousStep.emit()"
    >
      <span>
        {{ translated(this.LANUGAGE + ".goBackButton") }}
      </span>
    </button>
    <button
      class="btn btn-primary px-5"
      type="button"
      [disabled]="
        this.OTP.length !== INPUT_VERIFY_CODE_CONFIG.length || this.isInProcess
      "
      (click)="this.onVerifyOTP()"
    >
      @if (this.isInProcess) {
      <span
        class="spinner-border spinner-border-sm mr-1"
        role="status"
        aria-hidden="true"
      >
      </span>
      }
      <span> {{ translated(this.LANUGAGE + ".submitOTPButton") }} </span>
    </button>
  </div>
</div>
