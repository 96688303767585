import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { NgbModal } from '@shared/components/lib-ng/lib-ng-bootstrap/modal/modal';
import { InputPasswordComponent } from '@features/auth/shared/components/input-password/input-password.component';
import { ChangeEmailComponent } from '../change-email/change-email/change-email.component';
import { RouterLink } from '@angular/router';

import { catchError, filter, finalize, from, of, switchMap, tap } from 'rxjs';
import { AccountSettingService } from '@features/auth/core/domain/account/account-setting.service';
import { ProcessBarOverlayComponent } from '@shared/components/zaa-processbar-overlay/processbar-overlay.component';
import { ToastrService } from '@shared/components/lib-ngx/ngx-toastr/toastr/toastr.service';
import { ZaaFileV2LoaderDirective } from '@shared/directives/zaa-load-file-v2/zaa-load-file-v2.directive';
import { LinkGoogleAccountComponent } from '../link-google-account/link-google-account.component';
import Swal from 'sweetalert2';
import { UserInfoModel } from '@features/auth/core/domain/account/model/user-info.model';
import { DragDropImageComponent } from '@features/auth/shared/components/drag-drop-image/drag-drop-image.component';
import { ZaaTooltipDirective } from '@shared/directives/zaa-tooltip/standalone/zaa-tooltip.directive';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputPasswordComponent,
    RouterLink,
    ProcessBarOverlayComponent,
    ZaaFileV2LoaderDirective,
    LinkGoogleAccountComponent,
    ZaaTooltipDirective,
  ],
  providers: [],
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss'],
})
export class AccountInfoComponent implements OnInit {
  @ViewChild('imageTag', { static: false }) imageTag!: ElementRef;

  private readonly service = inject(AccountSettingService);

  private readonly modal = inject(NgbModal);
  private readonly formBuilder = inject(FormBuilder);

  protected toastService = inject(ToastrService);

  public isLoading: boolean = false;

  public accountInfo: UserInfoModel = {
    contact_id: '',
    isVerifiedEmail: false,
    user_info: {
      avatar: {},
      email: '',
      ho_ten: '',
      so_dien_thoai: '',
    },
    username: '',
  };

  ngOnInit() {
    this.getNewInfo();
  }

  getNewInfo() {
    this.isLoading = true;
    this.service
      .getAccountInfo()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (value) => {
          if (value && value.user_info) {
            this.accountInfo = value;
          }
        },
      });
  }

  onChangeEmail() {
    const changeEmail = this.modal.open(ChangeEmailComponent, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
    });
    changeEmail.dismissed.subscribe({
      next: (value) => {
        if (value) {
          this.getNewInfo();
        }
      },
    });
  }

  onChangeAvatar() {
    const modalRef = this.modal.open(DragDropImageComponent, {
      keyboard: true,
      size: 'lg',
      backdrop: 'static',
    });
    modalRef.componentInstance.fileV2 = this.accountInfo.user_info.avatar;

    modalRef.closed
      .pipe(
        filter((e) => e),
        switchMap((e) => this.service.updateAvatar(e)),
        tap((res) => {
          this.accountInfo.user_info.avatar = res?.avatar;
        })
      )
      .subscribe({
        next: (value) => {},
        error: (err) => {
          this.toastService.error(
            'Có lỗi xảy ra trong quá trình lưu ảnh, Vui lòng thử lại',
            'Lỗi hệ thống'
          );
        },
      });
  }

  getImageFile() {
    const imageElement = document.querySelector('img') as HTMLImageElement;
    const imageUrl = imageElement.src;

    from(fetch(imageUrl))
      .pipe(
        switchMap((response) => {
          if (!response.ok) {
            throw new Error('Failed to fetch image');
          }
          return from(response.blob());
        }),
        switchMap((blob) => this.service.upload(blob)),
        switchMap((uploadResponse) =>
          this.service.updateAvatar(uploadResponse)
        ),
        catchError((error) => {
          console.error('Error handling image upload', error);
          return of(null); // Return a fallback value or handle error appropriately
        })
      )
      .subscribe((updateResponse) => {
        if (updateResponse) {
          this.accountInfo.user_info['avatar'] = updateResponse['avatar'];
        }
      });
  }
  onRemoveAvatar(): void {
    from(
      Swal.fire({
        title: 'Xác nhận xóa ảnh đại diện',
        text: 'Bạn có chắc muốn xóa ảnh đại diện của bạn? Sau khi xóa bạn sẽ dùng ảnh đại hiện mạc định',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Hủy',
        reverseButtons: true,
      })
    ).subscribe({
      next: (value) => {
        if (value.isConfirmed) {
          this.getImageFile();
        }
      },
    });
  }

  onLinkGoogle() {}
}
