<!-- #Body -->
<div *transloco="let translated">
  <div class="modal-body">
    <div class="panel-tag ml-5 mr-5">
      Nhập email của bạn và đảm bảo bạn có thể truy cập vào hộp thư của email
      này, <br />
      Chúng tôi sẽ gửi cho bạn 1 mã xác thực.
    </div>

    <form class="m-5" [formGroup]="emailForm" (keydown.enter)="onSubmit()">
      @if(this.displayErrorMessage) {
      <div role="alert" class="alert alert-danger ng-star-inserted">
        {{ this.displayErrorMessage }}
      </div>
      }

      <div class="form-group">
        <label class="form-label" for="input-new-email-address">
          {{ translated(this.LANUGAGE + ".inputEmailLabel") }}
        </label>
        <input
          class="form-control"
          type="email"
          id="input-new-email-address"
          name="input-new-email-address"
          placeholder="Ví dụ: abc@gmail.com"
          formControlName="email"
        />
      </div>
    </form>
  </div>

  <!-- #Footer -->
  <div class="modal-footer justify-content-center border-top">
    <button
      class="mr-3 btn btn-outline-default waves-effect waves-themed"
      type="button"
      (click)="this.previousStep.emit()"
    >
      <span>
        {{ translated(this.LANUGAGE + ".goBackButton") }}
      </span>
    </button>
    <button
      class="btn btn-primary px-5 waves-effect waves-themed"
      type="button"
      [disabled]="this.showWaitingWhenVerifyEmail || this.emailForm.invalid"
      (click)="this.onSubmit()"
    >
      @if (showWaitingWhenVerifyEmail) {
      <span
        class="spinner-border spinner-border-sm mr-1"
        role="status"
        aria-hidden="true"
      >
      </span>
      }
      <span>
        {{ translated(this.LANUGAGE + ".sendVerifyEmailButton") }}
      </span>
    </button>
  </div>
</div>
