import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { InputEmailFormComponent } from '../input-email-form/input-email-form.component';
import { Config } from '@features/auth/shared/components/ng-otp-input/config';
import { CountdownButtonDirective } from '@features/auth/shared/directives/coutdown-and-disable.directive';
import { languagePathBuilder } from '@features/auth/shared/languages';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { ToastrService } from '@shared/components/lib-ngx/ngx-toastr/toastr/toastr.service';
import { NgOtpInputComponent } from '@features/auth/shared/components/ng-otp-input/ng-otp-input.component';
import { finalize } from 'rxjs';
import { AccountSettingService } from '@features/auth/core/domain/account/account-setting.service';
import { VerifyOTPCodeService } from '@features/auth/core/domain/account-first-login/verify-code-otp.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputEmailFormComponent,
    CountdownButtonDirective,
    TranslocoModule,
    NgOtpInputComponent,
  ],
  providers: [],
  selector: 'app-input-otp',
  templateUrl: './input-otp.component.html',
  styleUrls: ['./input-otp.component.css'],
})
export class InputOtpComponent {
  @Input() emailAddress: string = '';
  @Output() nextStep = new EventEmitter<object>();
  @Output() previousStep = new EventEmitter();

  public service = inject(AccountSettingService);
  public verifyOTPService = inject(VerifyOTPCodeService);
  public language = inject(TranslocoService);
  public toast = inject(ToastrService);

  public INPUT_VERIFY_CODE_CONFIG: Config = {
    length: 6,
    allowNumbersOnly: true,
    placeholder: '_',
    containerClass: 'form-group',
  };

  public OTP: string = '';

  // UI
  public LANUGAGE = languagePathBuilder([
    'component',
    'account',
    'changeEmail',
    'formEmail',
  ]);
  public isInProcess: boolean = false;
  public otpNotMatchErrorMessage: boolean = false;

  public displayErrorMessage = '';

  inputOTPChange(event: any) {
    this.OTP = event;
  }

  onVerifyOTP() {
    this.isInProcess = true;
    this.otpNotMatchErrorMessage = false;

    this.verifyOTPService
      .confirmOTPCode(this.OTP)
      .pipe(
        finalize(() => {
          this.isInProcess = false;
        })
      )
      .subscribe({
        next: (value) => {
          this.nextStep.emit();
        },
        error: (err) => {
          this.displayErrorMessage = err.message;
        },
      });
  }

  onResendEmail() {
    this.service.changeEmailAddress(this.emailAddress).subscribe({
      next: (value) => {},
      error: (err) => {
        this.displayErrorMessage = err.message;
      },
    });
  }

  showToast(translationKey: string, type: 'success' | 'error'): void {
    this.language.selectTranslate(this.LANUGAGE + translationKey).subscribe({
      next: (value) => {
        if (type === 'success') {
          this.toast.success(value);
        } else {
          this.toast.error(value);
        }
      },
    });
  }
}
