import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { InputEmailFormComponent } from '../input-email-form/input-email-form.component';
import { InputOtpComponent } from '../input-otp/input-otp.component';
import { ChangeEmailSuccessComponent } from '../change-email-success/change-email-success.component';
import { NgbActiveModal } from '@shared/components/lib-ng/lib-ng-bootstrap/modal/modal-ref';
import { TranslocoModule, provideTranslocoScope } from '@ngneat/transloco';
import { languagePathBuilder } from '@features/auth/shared/languages';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputEmailFormComponent,
    InputOtpComponent,
    ChangeEmailSuccessComponent,
    TranslocoModule,
  ],
  providers: [provideTranslocoScope('auth')],
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.css'],
})
export class ChangeEmailComponent {
  public activeModal = inject(NgbActiveModal);

  public CURRENT_STEP = 'inputEmailStep';
  public currentEmailAddress = '';

  LANUGAGE = languagePathBuilder([
    'component',
    'account',
    'changeEmail',
    'formEmail',
  ]);

  onSubmitEmail(event: any) {
    this.currentEmailAddress = event?.email ?? '';
    this.CURRENT_STEP = 'inputOtpStep';
  }
}
