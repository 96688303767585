<div class="loading-frame-wrap">
  @if (isLoading) {
    <div class="loading-overlay"></div>
  }

  <h3 class="mb-4">
    Thông tin cơ bản
  </h3>

  <div class="d-flex flex-column flex-md-row gap-20 mb-4">
    <div class="d-flex flex-column align-items-center gap-8">
      <img #imageTag [zaaLoadFileV2]="this.accountInfo.user_info.avatar" onerror="this.src='assets/img/logo.png'"
        alt="Avatar placeholder" style="max-width: 8rem; height: 8rem;" class="rounded-circle shadow-2 img-thumbnail"
        alt="">
      <a href="javascript:void(0)" (click)="onRemoveAvatar()" class="hover-underline">Gỡ bỏ</a>
    </div>
    <div class="text-center text-md-left">
      <p class="text-muted w-50 mx-auto mx-md-0">Để có hình đại diện đẹp hãy sử dụng hình ảnh có kích thước từ <strong class="fw-700">512 x 512</strong> pixel trở lên.</p>
      <button type="button" class="btn btn-outline-secondary waves-effect waves-themed" (click)="onChangeAvatar()">
        Tải lên hình ảnh
      </button>
    </div>
  </div>

  <ul class="user__profile-info">
    <li>
      <div class="row">
        <div class="col-4 col-lg-3 col-xl-2">
          <span>Họ tên</span>
        </div>
        <div class="col">
          {{ this.accountInfo.user_info.ho_ten }}
        </div>
      </div>
    </li>
    <li>
      <div class="row">
        <div class="col-4 col-lg-3 col-xl-2">
          <span>Mã nhân viên</span>
        </div>
        <div class="col">
          {{ this.accountInfo.username }}
        </div>
      </div>
    </li>
    <li>
      <div class="row">
        <div class="col-4 col-lg-3 col-xl-2">
          <span>Số điện thoại</span>
        </div>
        <div class="col">
          @if (this.accountInfo.user_info.so_dien_thoai) {
          {{ this.accountInfo.user_info.so_dien_thoai }}
          } @else {
          <span class="text-muted font-italic">Chưa cập nhật</span>
          }
        </div>
      </div>
    </li>
    <li>
      <div class="row">
        <div class="col-4 col-lg-3 col-xl-2">
          <span>Email khôi phục</span>
        </div>
        <div class="col position-relative cursor-pointer" (click)="onChangeEmail()">
          @if(this.accountInfo.isVerifiedEmail) {
          <i zaaTooltip [title]="'Đã xác thực'" [background]="'bg-success'"
            class="fal fa-check-circle fw-700 bg-success-200 text-success rounded-circle mr-1"></i>
          } @else {
          <i zaaTooltip [title]="'Chưa xác thực'" [background]="'bg-danger'"
            class="fal fa-times-circle fw-700 bg-danger-200 text-danger rounded-circle mr-1"></i>
          }
          <span class="cursor-default" (click)="$event.stopPropagation()">{{ this.accountInfo.user_info.email }}</span>
          <button type="button" style="transform: translate(0, -50%);"
            class="position-absolute top-center right-0 btn btn-icon waves-effect rounded-circle waves-themed">
            <i class="fal fa-arrow-right fs-lg"></i>
          </button>
        </div>
      </div>
    </li>
  </ul>
</div>
