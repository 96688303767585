import { Injectable, inject } from "@angular/core";
import { IVerifyOTPCode } from "./models/verify-code-otp.interface";
import { Observable, catchError, throwError } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { CoreAuthConfigService } from "@features/auth/core-auth-config.service";
import { ErrorBase } from "@core/models/error.model";
import { URI_APIs } from "@features/auth/const";
import { VerifyOTPErrorModel } from "./models/verify-otp-code.error";

@Injectable()
export class VerifyOTPCodeService implements IVerifyOTPCode {
  private readonly httpClient = inject(HttpClient);
  private readonly conf = inject(CoreAuthConfigService);

  resendOTPCode(args: any): Observable<any> {
    throw new Error("Method not implemented.");
  }

  confirmOTPCode(code: string, options?: any): Observable<any> {
    const url = this.conf.buildURL(URI_APIs.VERIFY_FIRST_LOGIN_CONFIRM_OTP);
    return this.httpClient.get(url + code).pipe(
      catchError((err: ErrorBase) => {
        return throwError(() => VerifyOTPErrorModel.handle(err))
      })
    )
  }

}
