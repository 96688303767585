import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IFileSysTemFileV2, IFileV2 } from './file-v2.interface';
import { GET_SERVER_FILE_INFO_API } from './config';

@Directive({
  standalone: true,
  selector: '[zaaLoadFileV2]',
})
export class ZaaFileV2LoaderDirective implements OnChanges {
  @Input() zaaLoadFileV2!: IFileV2; // Input to accept the file object
  @Input() noImageURL: string = 'assets/img/logo.png'; // Input to accept the file object
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private httpClient: HttpClient
  ) {}

  ngOnChanges() {
    if (this.zaaLoadFileV2 != null) {
      this.loadImage(this.zaaLoadFileV2);
    } else {
      this.updateImageSrc(this.noImageURL);
    }
  }

  private loadImage(fileV2: IFileV2) {
    this.updateImageSrc(this.noImageURL);
    const url = GET_SERVER_FILE_INFO_API;

    this.httpClient
      .post<Array<IFileSysTemFileV2>>(url, {
        list_server_file_name: [fileV2.server_file_name],
      })
      .subscribe({
        next: (files) => {
          if (files.length > 0) {
            const imageUrl =
              files[0]?.url;
            this.updateImageSrc(imageUrl); // Update existing <img> element's src
          } else {
            console.error('No files returned from the server.');
          }
        },
        error: (error) => {
          console.error('Error fetching image:', error);
        },
      });
  }

  private updateImageSrc(imageUrl: string) {
    // Set the 'src' attribute of the current img element
    this.renderer.setAttribute(this.el.nativeElement, 'src', imageUrl);
    this.renderer.setAttribute(this.el.nativeElement, 'alt', 'Loaded Image');
  }
}
