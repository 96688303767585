import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { EMAIL_PATTERN } from '@features/auth/shared/helpers/email-pattern';
import { InputEmailFormHelper } from './input-email-form.helper';
import { finalize } from 'rxjs';
import {
  TranslocoModule,
  TranslocoService,
  provideTranslocoScope,
} from '@ngneat/transloco';
import { languagePathBuilder } from '@features/auth/shared/languages';
import { ToastrService } from '@shared/components/lib-ngx/ngx-toastr/toastr/toastr.service';
import { AccountSettingService } from '@features/auth/core/domain/account/account-setting.service';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslocoModule],
  providers: [provideTranslocoScope('auth')],
  selector: 'app-input-email-form',
  templateUrl: './input-email-form.component.html',
  styleUrls: ['./input-email-form.component.css'],
})
export class InputEmailFormComponent implements OnInit {
  @Input() spinner: boolean = false;
  @Output() nextStep = new EventEmitter<object>();
  @Output() previousStep = new EventEmitter();

  public service = inject(AccountSettingService);
  public formBuilder = inject(FormBuilder);
  public toast = inject(ToastrService);
  public language = inject(TranslocoService);

  public emailForm!: FormGroup;

  // UI
  public LANUGAGE = languagePathBuilder([
    'component',
    'account',
    'changeEmail',
    'formEmail',
  ]);
  public showWaitingWhenVerifyEmail: boolean = false;
  public displayErrorMessage = '';

  ngOnInit() {
    this.emailForm = this.formBuilder.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern(EMAIL_PATTERN),
        ]),
      ],
    });
  }

  get formControls() {
    return this.emailForm.controls;
  }

  onSubmit() {
    if (this.emailForm.valid) {
      const { email } = this.emailForm.getRawValue();

      this.showWaitingWhenVerifyEmail = true;

      this.service
        .changeEmailAddress(email)
        .pipe(finalize(() => (this.showWaitingWhenVerifyEmail = false)))
        .subscribe({
          next: (value) => {
            this.nextStep.emit({ email });
          },
          error: (err) => {
            console.error(err);
            this.displayErrorMessage = err.message;
          },
        });
    } else {
      this.emailForm.markAllAsTouched();
    }
  }

  showToast(translationKey: string, type: 'success' | 'error'): void {
    this.language.selectTranslate(this.LANUGAGE + translationKey).subscribe({
      next: (value) => {
        if (type === 'success') {
          this.toast.success(value);
        } else {
          this.toast.error(value);
        }
      },
    });
  }
}
