<div class="modal-header border-bottom" *transloco="let translated">
  <h3 class="modal-title">
    <span>
      Địa chỉ Email
    </span>
  </h3>
  <button class="btn-close close"
          type="button"
          aria-label="Close"
          (click)="this.activeModal.close()">
    <span class="fal fa-times"
          aria-hidden="true">
    </span>
  </button>
</div>

@switch (this.CURRENT_STEP) {
@case ('inputEmailStep') {
<app-input-email-form [spinner]="true"
                      (nextStep)="this.onSubmitEmail($event)"
                      (previousStep)="this.activeModal.close()">
</app-input-email-form>
}

@case ('inputOtpStep') {
<app-input-otp [emailAddress]="this.currentEmailAddress"
               (previousStep)="this.CURRENT_STEP = 'inputEmailStep'"
               (nextStep)="this.CURRENT_STEP = ''">
</app-input-otp>
}
@default {
<app-change-email-success />
}
}
