import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { provideSocialAuthServiceConfig } from '../../login/login_with_method/login-by-google/login-by-google.provider';
import {
  GoogleLoginProvider,
  SocialAuthService,
} from '@abacritt/angularx-social-login';
import { LoginByGoogleService } from '@features/auth/core/domain/login/login-by-google.service';
import { AccountSettingService } from '@features/auth/core/domain/account/account-setting.service';
import { finalize, from, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { ToastrService } from '@shared/components/lib-ngx/ngx-toastr/toastr/toastr.service';

@Component({
  standalone: true,
  imports: [CommonModule],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useFactory: provideSocialAuthServiceConfig,
    },
    SocialAuthService,
    LoginByGoogleService,
  ],
  selector: 'app-link-google-account',
  templateUrl: './link-google-account.component.html',
  styleUrls: ['./link-google-account.component.css'],
})
export class LinkGoogleAccountComponent implements OnInit {
  service = inject(AccountSettingService);
  constructor() {}

  public googleService = inject(SocialAuthService);
  public toast = inject(ToastrService);

  public destroy$$ = new Subject<void>();
  public accessToken: string = '';

  // UI variablew
  public isWaitingForLogin: boolean = false;
  public waitingForLoadComponentData: boolean = false;
  public isWatingForSendTokenToServer: boolean = false;

  ngOnInit() {
    this.googleService.initState.pipe(takeUntil(this.destroy$$)).subscribe({
      next: (value) => {
        this.waitingForLoadComponentData = value;
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  async onLinkGoogleAccount() {
    if (this.isWatingForSendTokenToServer) {
      return;
    }

    this.isWaitingForLogin = true;

    from(this.googleService.getAccessToken(GoogleLoginProvider.PROVIDER_ID))
      .pipe(
        tap((token) => {
          this.accessToken = token;
          this.isWatingForSendTokenToServer = true;
        }),
        switchMap((accessToken) => {
          return this.service.linkGoogle(accessToken);
        }),
        finalize(() => {
          this.isWatingForSendTokenToServer = false;
          this.isWaitingForLogin = false;
        })
      )
      .subscribe({
        next: (value) => {},
        error: (err) => {
          this.toast.error('', 'Lỗi liên kết tài khoản google')
        },
      });
  }
}
