import { ErrorBase } from '@core/models/error.model';

export class AccountFirstLoginVerifyOTPError extends Error {
  constructor(mgs: string) {
    super(mgs);
  }
}

export class VerifyOTPErrorModel {
  public static handle(response: ErrorBase) {
    throw new AccountFirstLoginVerifyOTPError(response.desc?.message);
  }
}
